import React, { useEffect } from 'react';
import { Sidebar } from 'ui-components';
import { makeStyles } from '@material-ui/core/styles';
import IconP from '@material-ui/icons/Person'; 
import IconV from '@material-ui/icons/ViewModule';
import IconL from '@material-ui/icons/ExitToApp';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import SettingsIcon from '@material-ui/icons/Settings';
import { getToken, removeToken } from '../utils/authenticator';
import ls from 'local-storage';
import PatientPage from '../pages/patientPage';
import ListPatientsPage from '../pages/listPatientsPage';
import DashboardPage from '../pages/dashboardPage';
import ArchivedPatientsPage from '../pages/archivedPatientsPage';
import ModalSettings from 'ui-components/dist/ModalSettings/ModalSettings';
import { postUserPreferences } from '../api/accountsApi';
import { Redirect, Switch, Route, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { getLoggedInHospitalStaff } from '../api/hospitalApi';
import moment from 'moment';

const useStyles = makeStyles({
    formControl: {
        minWidth: 200,
        alignItems: 'left',
        paddingTop: 4,
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    background: {
        backgroundColor: '#f3f5fb',
        alignItems: 'center',
    },
    gridContainer: {
        maxWidth: 960,
        margin: 'auto',
        paddingLeft: 92,
        paddingTop: 20,
        paddingBottom: 20
    },
    pickerLabel: {
        paddingTop: 10,
    },
    topRow: {
        height: 250,
        display: 'flex',
    },
    header: {
        paddingTop: 24,
    },
    picker: {
    },
    pickerItems: {
        padding: 8
    },
    reviews: {
        minWidth: 800
    },
    addMeasurement: {
        paddingTop: 4,
    },
    measurementGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    measurementTextField: {
        margin: 0,
    },
    button: {
        width: '95%',
        marginBottom: 10,
    }
});

const getSettingsData = () => {
    return [
        {
            title: 'Temperature',
            id: 'temperature_unit',
            fields: [{
                type: 'radio',
                id: 'temperature_unit',
                initialValue: ls.get('temperature_unit') || '°C',
                fields: [{
                    text: 'Celsius',
                    value: '°C'
                },{
                    text: 'Fahrenheit',
                    value: '°F'
                }]
            }]
        },{
            title: 'Weight',
            id: 'weight_unit',
            fields: [{
                type: 'radio',
                id: 'weight_unit',
                initialValue: ls.get('weight_unit') || 'kg',
                fields: [{
                    text: 'Kilograms (kg)',
                    value: 'kg'
                },{
                    text: 'Stone and Pounds (St/lbs)',
                    value: 'st'
                },{
                    text: 'Pounds (lbs)',
                    value: 'lb'
                }]
            }]
        },{
            title: 'Blood Glucose',
            id: 'blood_glucose_unit',
            fields: [{
                type: 'radio',
                id: 'blood_glucose_unit',
                initialValue: ls.get('blood_glucose_unit') || 'mmol/L',
                fields: [{
                    text: 'mmol/L',
                    value: 'mmol/L'
                },{
                    text: 'mg/dL',
                    value: 'mg/dL'
                }]
            }]
        }
    ]
};

const getActiveTab = (pathname) => {
    if(pathname.includes('archived')){
        return 'archived-patients'
    } else if(pathname.includes('dashboard')){
        return 'dashboard'
    } else if(pathname.includes('patient')){
        return 'patients'
    } else {
        return null;
    }
}

const getLoginLink = (theLocation) => {
    const redirect = theLocation.pathname;
    return "/login?next=" + redirect;
}

export default function MainPage(props) {
    const classes = useStyles(); 
    let location = useLocation();

    const [settingsOpen, setSettingsOpen] = React.useState(false);
    const [loggedIn, setLoggedIn] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [user, setUser] = React.useState(null);
    const { boot, shutdown } = useIntercom();

    useEffect(() => {
        const getLoggedInHospitalStaffFromApi = async () => {
            const result = await getLoggedInHospitalStaff(getToken());
            setUser(result);
        }

        if(!getToken()) {
            setLoggedIn(false);
        } else {
            getLoggedInHospitalStaffFromApi();
            setLoggedIn(true);
            if(!process.env.REACT_APP_INTERCOM_APP_ID) {
                setLoading(false);
            }
        }
    }, []);

    useEffect(()=>{
        if(loggedIn && process.env.REACT_APP_INTERCOM_APP_ID && user){
            const intercomBootWithProps = ((result, unixSeconds) => boot({
                userId: result.user,
                email: result.email,
                name: result.full_name,
                userHash: result.intercom_user_hash,
                createdAt: unixSeconds,
                customAttributes: { hospital: result.hospital.name },
            }));

            intercomBootWithProps(user, moment(user.created).unix());
            setLoading(false);
        }     
    },[loggedIn, boot, user])

    const handleLogout = () => {
        removeToken();
        setLoggedIn(false);
        if(process.env.REACT_APP_INTERCOM_APP_ID){
            shutdown();
        }
    }

    const handleClickSettings = () => {
        if(!getToken()) {
            setLoggedIn(false);
        } else {
            setLoggedIn(true);
            setSettingsOpen(true);
        }
    }
    
    const sections = [
        [{
            id: 'dashboard',
            title: 'Dashboard',
            icon: <IconV />
        },{
            id: 'patients',
            title: 'Active Patients',
            icon: <IconP />
        },{
            id: 'archived-patients',
            title: 'Archived Patients',
            icon: <AssignmentReturnIcon />
        },{
            id: 'adminPortal',
            title: 'Admin Portal',
            hyperlink: `${process.env.REACT_APP_CMS_BASE_URL}hospital`,
            icon: <DesktopWindowsIcon />
        },{
            id: 'settings',
            title: 'Settings',
            onClick: handleClickSettings,
            icon: <SettingsIcon />
        }],
        [{
            id: 'logout',
            title: 'Log Out',
            onClick: handleLogout,
            icon: <IconL />
        }]
    ];


    const handleSave = (params) => {
        postUserPreferences(params, getToken());
        setSettingsOpen(false);
    };

    return (
        <div className={classes.background}>
            {!loggedIn 
                ? <Redirect
                    to={getLoginLink(location)}
                /> 
                : null
            }
            {user ?
                <Sidebar
                    tabs={sections}
                    activeTab={getActiveTab(location.pathname.substr(1))}
                />
                : null
            }

            {!loading && user
                ? <Switch>
                    <Route path="/dashboard">
                        <DashboardPage user={user}/>
                    </Route>
                    <Route path="/archived-patients/:patientId">
                        <PatientPage user={user} archived />
                    </Route>
                    <Route path="/archived-patients">
                        <ArchivedPatientsPage  user={user}/>
                    </Route>
                    <Route path="/patients/:patientId">
                        <PatientPage  user={user}/>
                    </Route>
                    <Route exact path={["/","/patients"]}>
                        <ListPatientsPage  user={user}/>
                    </Route>
                    <Route>
                        <div className={classes.gridContainer}><h1>Sorry, page not found</h1></div>
                    </Route>
                </Switch>
                : null
            }

            {user ?
                <ModalSettings
                    title="Settings"
                    groups={getSettingsData()}
                    onSave={handleSave}
                    modalProps={{
                        open: settingsOpen,
                        onClose: () => setSettingsOpen(false)
                    }}
                />
                : null
            }
        </div>
    )
} 
