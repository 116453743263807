import React from 'react';
import './App.css';
import { Switch, Route } from 'react-router-dom';
import LoginPage from './pages/loginPage';
import MainPage from './pages/mainPage';

function App() {
  document.title=process.env.REACT_APP_TITLE;
  return (
    <div>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/">
          <MainPage />
        </Route>
      </Switch>
    </div>
    );
}

export default App;
