import React, { useEffect } from 'react';
import {
    DataArea,
    StatisticCardTable,
} from 'ui-components';
import { getPatientData } from '../api/patientsApi';
import { getToken } from '../utils/authenticator';
import { prepDataAreaData, prepMeasurementTableData } from '../utils/pageOperations/patientPageOperations';
import { Grid } from '@material-ui/core';

export default function DataAreaContainer(props) {
    const [loading, setLoading] = React.useState(true);
    const [preparedData] = React.useState({});
    const [noData, setNoData] = React.useState(false);

    useEffect(() => {
        let isMounted = true; // flag for component mount status
        setLoading(true);
        setNoData(false);

        const getPatientDataFromApi = async (measurementType) => {
            let result = await getPatientData(props.patientId, measurementType.key, getToken());

            if (isMounted){ // Only run if this component is still mounted
                result = result.filter(entry => entry[measurementType.key] !== null);

                preparedData.data = prepDataAreaData(
                    result,
                    measurementType.key,
                    props.startDate,
                    props.endDate,
                );
                preparedData.tableData = prepMeasurementTableData(
                    result,
                    measurementType.key,
                    props.measurementType.unit,
                    props.startDate,
                    props.endDate,
                );
                preparedData.range = props.measurementType.range(preparedData.data)

                preparedData.tooltipFormatter = (datum) => props.measurementType.displayWithUnit(datum.y);
                preparedData.yAxisTickFormatter = (tick) => props.measurementType.displayWithUnit(tick);

                if(result.length < 1) {
                    preparedData.warning = "No data for this measurement";
                    setNoData(true);
                } else if (preparedData.data.length < 1) {
                    preparedData.warning = "No data for this timeframe";
                    setNoData(true);
                } else {
                    preparedData.warning = null
                    setNoData(false);
                }

                setLoading(false);
            }
        };

        if(!props.conditionLoading) {
            getPatientDataFromApi(props.measurementType);
        }

        return function cleanup() {
            isMounted = false; // function called when the component is unmounted
        };
    }, [props, preparedData])

    return (
        <>
        <Grid item xs={12}>
            {loading
                ?<DataArea loading={loading}/>
                :<DataArea 
                    data={[preparedData]} 
                    startDate={props.startDate}
                    endDate={props.endDate}
                    
                    loading={loading}
                />}
        </Grid>
        <Grid item xs={12}>
            {loading
                ?<StatisticCardTable loading={loading}/>
                : noData
                    ? null
                    : <StatisticCardTable 
                    data={preparedData.tableData} 
                />
            }
        </Grid>
        </>
    )
}

