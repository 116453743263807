import moment from 'moment';
import ls from 'local-storage';
import { convertToBloodGlucoseUnit, convertToWeightUnit, roundToX } from '../unitOperations';

export const prepDataAreaData = (response, field, startDate, endDate) => {
    const preppedData = [];

    response.forEach((element) => {
        if (moment(startDate.format("Y-M-D")).isAfter(element.date) || 
                moment(endDate.format("Y-M-D")).isBefore(element.date)) {
            return;
        }
        
        if (element[field] !== null) {
            preppedData.push({
                'x': Date.parse(element.time),
                'y': parseFloat(roundToX(element[field], 2))
            })
        }
    });

    return preppedData.reverse();
}

export const prepMeasurementTableData = (response, field, valueType ,startDate, endDate) => {
    const preppedData = [];

    response.forEach((element) => {
        let value;
        if(field === 'temperature') {
            if(ls.get('temperature_unit') === '°F'){
                value = `${Math.round((parseFloat((element[field]) * 1.8 + 32)) * 100) / 100 } °F`
            } else {
                value = `${Math.round((parseFloat(element[field])) * 100) / 100} °C`
            }
        } else if(field === 'weight') {
            if(ls.get('weight_unit') === 'st') {
                value = `${convertToWeightUnit(element[field], ls.get('weight_unit')).stone}st
                ${roundToX(convertToWeightUnit(element[field], ls.get('weight_unit')).lbs, 2)}lbs`
            } else if(ls.get('weight_unit') === 'lb') {
                value = `${roundToX(convertToWeightUnit(element[field], ls.get('weight_unit')), 2)}lbs`
            } else {
                value = `${roundToX(element[field], 2)} kg`
            }
        } else if(field === 'blood_glucose') {
            if(ls.get('blood_glucose_unit') === 'mg/dL'){
                value = `${roundToX(convertToBloodGlucoseUnit(element[field], ls.get('blood_glucose_unit')), 2)} mg/dL`
            } else {
                value = `${roundToX(element[field], 2)} mmol/L`
            }
        } else {
            value = `${roundToX(element[field], 2)} ${valueType}`
        }

        if(moment(startDate.format("Y-M-D")).isAfter(element.date)) {
            
        } else if (moment(endDate.format("Y-M-D")).isBefore(element.date)) {
            
        } else if (element[field] !== null) {
            preppedData.push({
                'date': moment(element.time).format('MMM Do YYYY'),
                'time': moment(element.time).format('h:mm a'), 
                'value': `${value}`
            });
        }
    });

    return preppedData;
}

export const prepReviewData = (data, user) => {
    const result = [];

    data.forEach((element) => {
        const review = {
            id: element.id || '-',
            date: {label: element.time ? moment(element.time).format('DD MMM Y') : '-'},
            reviewedByName: {label: element.reviewed_by_name || '-'},
            notes: {label: element.notes || '-'},
            disabled: element.reviewed_by === user ? false : true
        };

        result.push(review);
    })

    return result;
};

export const prepPatientDetails = (data) => {

    const patientDetails = [
        {label: 'ID', value: data.id},
        {label: 'Hospital Patient ID', value: data.hospital_patient_id},
        {label: 'Name', value: data.name},
        {label: 'Email', value: data.email},
        {
            label: 'Last Review', 
            value: data.latest_review_event ? moment(data.latest_review_event.time).format('MMM Do, hh:mma') : null
        },
        {
            label: 'Last Reviewed By', 
            value: data.latest_review_event ? data.latest_review_event.reviewed_by_name : null
        },
        {label: 'Phone', value: data.phone_number},
        {label: 'Hospital', value: data.hospital_name || '-'},
        {label: 'Last Used', value: data.last_used ? moment(data.last_used).format('MMM Do, hh:mma') : null},
        {label: 'Caregiver Phone', value: data.caregiver_phone || '-'},
    ]

    if(data.consultant_name){
        patientDetails.push({label: 'Consultant Name', value: data.consultant_name});
    }

    return (patientDetails);
}

export const isSpirometryCondition = (condition) => {
    const spirometryConditions = [
        'ipf',
        'lung-transplant',
        'cystic-fibrosis',
        'copd',
        'asthma',
    ];

    return spirometryConditions.includes(condition) ? true : false;
}

export const prepAllowedDates = (data) => {
    const allowedDates = [];
    
    data.forEach((element) => {
        if(!allowedDates.includes(moment(element.start_time).format('DD/MM/YYYY'))){
            allowedDates.push(moment(element.start_time).format('DD/MM/YYYY'));
        }
    });

    return allowedDates;
}

export const prepSpirometryData = (data) => {
    return data.map((element) => {
        return {
            'Date': moment(element.start_time).calendar(null, {
                sameDay: '[Today]',
                nextDay: '[Tomorrow]',
                nextWeek: 'dddd',
                lastDay: '[Yesterday]',
                lastWeek: '[Last] dddd',
                sameElse: 'MMM DD YYYY'
            }),
            'Time': moment(element.start_time).format('h:mma'),
            'FEV1': element.fev1,
            'FVC': element.fvc,
            'FEV1 / FVC': element.fev1_fvc,
            'PEF': element.pef,
        }
    })
};

export const prepDataForNewDate = (newDate, data) => {
    const newData = [];
    
    data.forEach((element) => {
        if(moment(element.start_time).format('DD/MM/YYYY') === moment(newDate).format('DD/MM/YYYY')) {
            let spirometryMeasurement = element;
            spirometryMeasurement.flowVolumeCurveData = [];
            spirometryMeasurement.flowVolumeRange = 6;
            spirometryMeasurement.flowVolumePointsOfInterest = [];
            spirometryMeasurement.volumeTimeCurveData = [];
            spirometryMeasurement.volumeTimeRange = 6;
            spirometryMeasurement.volumeTimePointsOfInterest = [];

            newData.push(spirometryMeasurement);
        }
    });

    return(newData);
};

export const prepFlowVolumePointsOfInterest = (spirometryData, graphData) => {
    const getEnd = () => {
        let highest = {x:0, y:0};
        graphData.forEach((element) => {
            if (element.y > highest.y ) {
                highest = element;
            }
        });

        return highest.x;
    };

    const end = getEnd();

    return [{
        start: 0,
        end: end,
        value: parseFloat(spirometryData.pef),
        label: 'PEF'
    }];
};

export const isAddMeasurementSubmitDisabled = (primaryMeasurement, secondaryMeasurement, needsSecondaryMeasurement) => {
    if(needsSecondaryMeasurement && !secondaryMeasurement) {
        return true;
    }

    if(!primaryMeasurement) {
        return true;
    }

    return false;
}

export const needsSecondaryMeasurement = (key) => {
    switch(key) {
    case 'weight':
        if(ls.get('weight_unit') === 'st') {
            return true;
        }
        break;
    default:
        return false;
    }
};